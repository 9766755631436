import TomSelect from "tom-select"
import { get } from "@rails/request.js"

const Autocomplete = function(selector, queryUrl, identification) {
  const autocomplete = new TomSelect(selector, {
    create: false,
    valueField: "value",
    labelField: "label",
    searchField: "label",
    maxItems: 1,
    plugins: [
      "remove_button",
      "no_backspace_delete"
    ],
    render: {
      no_results: (_data, _escape) => '<div class="no-results">Nenhum resultado encontrado</div>'
    },
    load: async (query, callback) => {
      if (!query.length || query.length < 5) return callback();

      const response = await get(`${queryUrl}?key=${encodeURIComponent(query)}`, {
        responseKind: "json"
      });
      if (response.ok) {
        callback(await response.json)
      } else {
        callback()
      }
    }
  })

  if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
    if (!window.autocompletes) { window.autocompletes = {} }
    window.autocompletes[identification] = autocomplete
  }

  return autocomplete
}

export default Autocomplete
