import { Controller } from "@hotwired/stimulus"
import Autocomplete from "../concerns/autocomplete"

export default class extends Controller {
  static targets = ["idiomaId", "destroy", "check", "select", "selectWrapper"]

  connect() {
    this.selectWrapperOriginalDisplay = this.selectWrapperTarget.style["display"]
    this.idiomaIdTarget.value == this.data.get("idiomaId") ? this.turnOn() : this.turnOff()

    this.select = Autocomplete(
      `#${this.selectTarget.id}`,
      this.data.get('url') ,
      `Proficiencia${this.data.get("idiomaId")}`
    )
  }

  toggle() {
    this.checkTarget.checked ? this.turnOn() : this.turnOff()
  }

  turnOn() {
    this.idiomaIdTarget.value = this.data.get("idiomaId")
    this.destroyTarget.value = ""
    this.selectWrapperTarget.style["display"] = this.selectWrapperOriginalDisplay
  }

  turnOff() {
    this.idiomaIdTarget.value = ""
    this.destroyTarget.value = "1"
    this.selectWrapperTarget.style["display"] = "none"
  }
}
