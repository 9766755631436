import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { showElement, hideElement } from "@uenf/layout/src/index"
// FIXME: version 3 is unsecure, can't run version 4
// const pdfjsLib = require("pdfjs-dist/legacy/build/pdf")
// pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.js`;

export default class extends Controller {
  static targets = ["canvas", "messageHolder"]
  static values = {
    downloadUrl: String,
  }

  connect() {
    this.show()
  }

  async show() {
    showElement(this.messageHolderTarget)
    this.messageHolderTarget.innerHTML = 'Carregando, aguarde...'
    const response = await post(this.downloadUrlValue, { responseKind: "json" })
    if (response.ok) {
      const pdfData = await response.json
      // window.data = pdfData
      pdfjsLib.getDocument({ data: atob(pdfData.data) }).promise.then(
        pdf => {
          pdf.getPage(1).then(page => {
            const viewport = page.getViewport({ scale: 1.5 })
            this.canvasTarget.height = viewport.height;
            this.canvasTarget.width = viewport.width;
            const canvasContext = this.canvasTarget.getContext("2d")
            const outputScale = window.devicePixelRatio || 1
            const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null
            page.render({ canvasContext, viewport, transform }).promise.then(() => {
              hideElement(this.messageHolderTarget)
            })
          })
        },
        reason => {
          this.messageHolderTarget.innerHTML = `Ocorreu um erro ao carregar o comprovante:<br>${reason}`
        }
      )
    } else {
      this.messageHolderTarget.innerHTML = 'Ocorreu um erro ao carregar o comprovante.'
    }
  }
}
