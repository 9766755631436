import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
import Cropper from 'cropperjs';
import { createElement } from "@uenf/layout/src/index";
import { routes } from "../routes";

export default class extends Controller {
  static targets = [
    "alert", "message", "player", "uploadContainer", "input", "cutter",
    "cropped", "fotografar", "cortar", "salvar", "voltar"
  ]

  connect() {
    this.hide(this.retrieverElement(), "cutter", "cropped", "fotografar", "cortar", "salvar", "voltar")
    if (this.supported()) {
      this.initialize()
    } else {
      this.alert(
        "Seu navegador não possui suporte a esta operação. " +
        "Por favor, atualize seu navegador para a versão mais recente."
      )
    }
  }

  initialize() {
    if (this.take()) {
      this.activatePlayer()
    } else {
      this.showInput()
    }
  }

  read() {
    this.createCanvas()
    if (this.fileToCanvas()) {
      this.hide("uploadContainer")
      this.show("cutter", "cortar", "voltar")
    }
  }

  fotografar(event) {
    event.preventDefault()
    this.createCanvas()
    this.takeSnapshot()
    this.hide("fotografar", "player")
    this.show("cutter", "cortar", "voltar")
    this.flashEffect()
    this.activateCropper()
  }

  cortar(event) {
    event.preventDefault()
    this.croppedCanvas = this.cropper.getCroppedCanvas()
    this.croppedTarget.appendChild(this.croppedCanvas)
    this.cropper.destroy()
    this.hide("cortar", "cutter")
    this.show("salvar", "cropped")
  }

  async salvar() {
    const imageBase64 = this.croppedCanvas.toDataURL()
    const formData = new FormData();
    formData.append("foto", imageBase64);
    await patch(routes.editAlunoFotoPath(this.data.get('alunoId')), { body: formData });
  }

  voltar(event) {
    event.preventDefault()
    if (this.salvarTarget.style.display != "none") {
      this.croppedCanvas.parentNode.removeChild(this.croppedCanvas)
      this.activateCropper()
      this.hide("cropped", "salvar")
      this.show("cutter", "cortar", "voltar")
    } else {
      this.cropper.destroy()
      this.createCanvas()
      if (this.take())
        this.show("fotografar", "player")
      else
        this.show("uploadContainer")
      this.hide("cutter", "cortar", "voltar")
    }
  }

  showInput() {
    this.show("uploadContainer")
  }

  createCanvas() {
    const existingCanvas = this.canvas()
    if (existingCanvas) { this.cutterTarget.removeChild(existingCanvas) }

    const canvas = createElement("canvas")
    canvas.width = 0
    canvas.height = 0
    this.cutterTarget.appendChild(canvas)
  }

  fileToCanvas() {
    const [file] = this.inputTarget.files
    if (file.type.match("image.*")) {
      const image = new Image()
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (evt) => {
        if (evt.target.readyState == FileReader.DONE) {
          image.src = evt.target.result
          image.onload = () => {
            const canvas = this.canvas()
            const context = canvas.getContext('2d')
            const width = image.width
            const height = image.height
            canvas.width = width
            canvas.height = height
            context.clearRect(0, 0, width, height)
            context.drawImage(image, 0, 0, width, height)
            this.activateCropper()
          }
        }
      }
      return true
    } else {
      window.alert("O arquivo deve ser uma imagem.")
      return false
    }
  }

  takeSnapshot() {
    const canvas = this.canvas()
    const context = canvas.getContext('2d')
    const width = this.playerTarget.videoWidth
    const height = this.playerTarget.videoHeight
    canvas.width = width
    canvas.height = height
    context.clearRect(0, 0, width, height)
    context.drawImage(this.playerTarget, 0, 0, width, height)
  }

  flashEffect() {
    const canvas = this.canvas()
    canvas.style["opacity"] = "1"
    canvas.style["-webkit-animation"] = "flash 1s"
    canvas.style["animation"] = "flash 1s"
  }

  activateCropper() {
    this.cropper = new Cropper(this.canvas(), {
      aspectRatio: 2.7 / 3.2,
      zoomable: false
    })
  }

  activatePlayer() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(stream => {
        this.playerTarget.srcObject = stream
        this.show("player", "fotografar")
      })
      .catch(err => {
        if (err.name === "NotAllowedError") {
          this.alert("É necessário dar autorização ao uso da câmera.")
        } else if (err.name === "NotFoundError") {
          this.alert("Não foi possível detectar uma câmera em seu equipamento.")
        } else {
          this.alert(
            "Ocorreu um erro desconhecido ao tentar acessar a câmera. " +
            "Se possível, tente com outro equipamento."
          )
        }
      })
  }

  supported() {
    if (this.take()) {
      return "mediaDevices" in navigator &&
            "toBlob" in HTMLCanvasElement.prototype
    } else {
      return typeof(FileReader) != "undefined"
    }
  }

  take() {
    return this.data.get('mode') === 'take'
  }

  retrieverElement() {
    return this.take() ? "player" : "uploadContainer"
  }

  canvas() {
    return this.cutterTarget.querySelector("canvas")
  }

  alert(message) {
    const content = this.alertTarget.innerHTML.replace(/ALERT_MESSAGE/g, message)
    this.messageTarget.insertAdjacentHTML("beforebegin", content)
  }

  show(...list) {
    [...list].forEach(item => this[`${item}Target`].style.display = "")
  }

  hide(...list) {
    [...list].forEach(item => this[`${item}Target`].style.display = "none")
  }
}
