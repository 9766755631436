import { masks } from "@uenf/layout/src/index";

masks.nota = {
  mask: Number,
  scale: 1,
  min: 0,
  max: 10,
  signed: false,
  radix: ".",
  mapToRadix: [","],
  padFractionalZeros: true,
  normalizeZeros: true
};

masks.falta = {
  mask: Number,
  min: 0,
  scale: 0,
  signed: false
};

masks['ano-semestre-calendario'] = {
  mask: "0000/0a",
  prepare: str => str.toUpperCase(),
}

masks['exigencia-programa-codigo'] = {
  mask: "UENF000"
}

masks['sei'] = {
  mask: 'SEI-000000/000000/0000'
}
