import { Controller } from "@hotwired/stimulus"
import Autocomplete from "../concerns/autocomplete"

export default class extends Controller {
  static targets = ["template", "placeholder", "select"]

  connect() {
    console.log(this.selectTarget.id)
    this.select = Autocomplete(
      `#${this.selectTarget.id}`,
      this.data.get("url") ,
      this.data.get("identification")
    )
  }

  addItem(e) {
    e.preventDefault()

    const disciplinaId = this.selectTarget.value
    if (!disciplinaId || disciplinaId == '') return;

    const disciplinaDescricao = this.selectTarget.selectedOptions[0].text
    const content = this.templateTarget.innerHTML
      .replace(/TEMPLATE_RECORD/g, new Date().valueOf())
      .replace(/DISCIPLINA_DESCRICAO/g, disciplinaDescricao)
      .replace(/DISCIPLINA_ID/g, disciplinaId)
    this.placeholderTarget.insertAdjacentHTML("beforebegin", content)
    this.select.clear(true) // silent: true
  }

  removeItem(e) {
    e.preventDefault()

    const wrapper = e.target.closest(".nested-fields")
    wrapper.style.display = "none"
    wrapper.querySelector("input[name*='_destroy']").value = 1
  }
}
