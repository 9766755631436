import { Controller } from "@hotwired/stimulus"
import { showElement, hideElement } from "@uenf/layout/src/index"

const isVisible = (element) => element.offsetWidth > 0 || element.offsetHeight > 0 || element.getClientRects().length > 0;

export default class extends Controller {
  static targets = ["input", "message", "panel"]

  connect() {
    this.change()
    setInterval(() => this.change(), 500);
  }

  change() {
    let message = "Com o cadastro atual, o aluno cumprirá o requisito desta disciplina <strong>SE</strong>"

    const conjuntos = this.inputTargets
      .filter(isVisible)
      .map(autocompleteElement => Array.from(autocompleteElement.selectedOptions).map(option => option.text))
      .filter((array) => array.length > 0)

    if (conjuntos.length == 0) {
      this.messageTarget.innerHTML = ""
      hideElement(this.panelTarget);
    } else if (conjuntos.length == 1) {
      const conjunto = conjuntos[0]
      if (conjunto.length == 1) {
        message += ' o aluno cursar a disciplina '
      } else {
        message += ' o aluno cursar <strong>TODAS</strong> as disciplinas a seguir: '
      }
      message += `<strong>${conjunto.join(", ")}</strong>.`
      this.messageTarget.innerHTML = message
      showElement(this.panelTarget);
    } else {
      message += ' o aluno cumprir <strong>UMA</strong> das seguintes condições:'
      message += '<ul>'
      for (const conjunto of conjuntos) {
        message += '<li>'
        if (conjunto.length == 1) {
          message += ' o aluno cursar a disciplina '
        } else {
          message += ' o aluno cursar <strong>TODAS</strong> as disciplinas a seguir: '
        }
        message += `<strong>${conjunto.join(", ")}</strong>.`
        message += '</li>'
      }
      message += '</ul>'
      this.messageTarget.innerHTML = message
      showElement(this.panelTarget);
    }
  }
}
