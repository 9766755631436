import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    with: String
  }

  connect() {
    this.element.dataset['action'] = "submit->disable-submit#disable"

    if (!this.hasWithValue) {
      this.withValue = "Processando...";
    }
  }

  disable() {
    this.submitButtons().forEach(button => {
      button.disabled = true
      const oldValue = button.value
      button.value = this.withValue
      setTimeout(() => {
        button.value = oldValue
        button.disabled = false
      }, 20_000)
    })
  }

  submitButtons() {
    return this.element.querySelectorAll("input[type=submit], button[type=submit]")
  }
}
