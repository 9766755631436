import { Controller } from "@hotwired/stimulus";
import { isBlank, showIf } from "@uenf/layout/src/index";

export default class extends Controller {
  static targets = [
    "sexoCivilSelect",
    "nacionalidadeSelect",
    "identidadeGroup",
    "documentoMilitarGroup",
    "eleitorGroup",
    "passaporteGroup",
    "rneGroup"
  ];

  connect() {
    this.updateVisibility();
  }

  updateVisibility() {
    showIf(this.documentoMilitarGroupTarget, this.brasileiro() && this.masculino());
    showIf(this.identidadeGroupTarget, this.brasileiro());
    showIf(this.eleitorGroupTarget, this.brasileiro());
    showIf(this.passaporteGroupTarget, this.estrangeiro());
    showIf(this.rneGroupTarget, this.estrangeiro());
  }

  masculino() {
    return this.sexoCivilSelectTarget.value === 'Masculino';
  }

  brasileiro() {
    return this.nacionalidade() === 'Brasileira';
  }

  estrangeiro() {
    const nacionalidade = this.nacionalidade();
    return !isBlank(nacionalidade) && nacionalidade !== 'Brasileira';
  }

  nacionalidade() {
    return [...this.nacionalidadeSelectTarget.options].find(option => option.selected).text;
  }
};
