import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { routes } from "../routes";

export default class extends Controller {
  static targets = ["form"]

  async redirect(event) {
    event.preventDefault();

    // disable button to avoid double submit
    const button = event.target;
    button.disabled = true;
    const buttonText = button.innerHTML;
    button.innerHTML = 'Processando...'
    setTimeout(() => {
      button.innerHTML = buttonText;
      button.disabled = false;
    }, 10_000);

    // submit
    const response = await post(routes.minhaBibliotecaPath());
    if (response.ok) {
      const payload = await response.json;
      console.log(`URL: ${payload['url']}`)
      this.formTarget.action = payload['url'];
      this.formTarget.submit();
    } else {
      console.error("Não foi possível obter URL");
    }
  }
}
