import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import { createElement } from "@uenf/layout/src/index";
import { routes } from "../routes";

export default class extends Controller {
  static targets = ["select"];

  async change(e) {
    const areaDeConcentracaoId = e.target.value;
    this.selectTarget.innerHTML = '';
    this.selectTarget.appendChild(createElement('option', { value: '' }));
    if (!areaDeConcentracaoId) return;

    const atividade = linha => linha.ativa ? '' : '[Inativa]';
    const response = await get(
      `${routes.querylinhasDePesquisaPath()}?area_de_concentracao_id=${areaDeConcentracaoId}`,
      { responseKind: "json" }
    );
    if (response.ok) {
      const linhasDePesquisa = await response.json;
      for (let linha of linhasDePesquisa) {
        const element = createElement(
          'option', { value: linha.id }, `${linha.nome}${atividade(linha)}`
        );
        this.selectTarget.appendChild(element);
      }
    } else {
      console.log(`ERRO: ${response.code}`);
    }
  }
}
