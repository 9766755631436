import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["turmaDeSelect", "turmaParaSelect"];
  static values = {
    destinos: String,
  };

  connect() {
    this.destinosData = JSON.parse(this.destinosValue);
  }

  change() {
    this.cleanOptions();
    this.loadNewOptions();
  }

  cleanOptions() {
    while (this.turmaParaSelectTarget.options.length > 0) {
      this.turmaParaSelectTarget.remove(0)
    }
  }

  loadNewOptions() {
    const origemValue = this.turmaDeSelectTarget.value;
    if (!origemValue) return;

    this.destinosData[origemValue].forEach(pair => {
      this.turmaParaSelectTarget.appendChild(new Option(pair[0], pair[1], false, true));
    });
  }
};
