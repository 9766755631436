import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js"
import { createElement } from "@uenf/layout/src/index";
import { routes } from "../routes";

export default class extends Controller {
  static targets = ["centrosSelect", "laboratoriosSelect"];

  async change() {
    const response = await get(
      routes.queryLaboratoriosPath({ disciplina_centro_id: this.centrosSelectTarget.value }),
      { responseKind: "json" }
    );

    this.laboratoriosSelectTarget.innerHTML = "";

    if (response.ok) {
      this.laboratoriosSelectTarget.appendChild(createElement("option", { value: "" }));
      const laboratoriosData = await response.json;
      laboratoriosData.forEach(laboratorio => {
        this.laboratoriosSelectTarget.appendChild(
          createElement("option", { value: laboratorio.id }, laboratorio.descricao)
        );
      });
    }
  }
};
