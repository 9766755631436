import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static values = {
    url: String,
    method: String
  };

  async run(event) {
    event.preventDefault();
    console.log(this.methodValue);
    console.log(this.urlValue);
    const request = new FetchRequest(this.methodValue, this.urlValue);
    await request.perform();
  }
};
