import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inscricaoTemplate", "inscricoesList", "turmaTemplate", "turmasList", "submit"];

  connect() {
    this.updateSubmit();
  }

  adicionar(event) {
    event.preventDefault();

    const turmaBox = event.target.closest(".nested-fields");
    const horariosBox = turmaBox.querySelector(".horarios");
    const prerequisitosBox = turmaBox.querySelector(".pre-requisitos");
    const corequisitosBox = turmaBox.querySelector(".co-requisitos");
    const horariosContent = horariosBox ? horariosBox.outerHTML : "";
    const prerequisitosContent = prerequisitosBox ? prerequisitosBox.outerHTML : "";
    const corequisitosContent = corequisitosBox ? corequisitosBox.outerHTML : "";

    const content = this.inscricaoTemplateTarget.innerHTML
      .replace(/TEMPLATE_INDEX/g, new Date().valueOf())
      .replace(/DESCRICAO_BASICA/g, turmaBox.dataset["descricaoBasica"])
      .replace(/DESCRICAO_ESTENDIDA/g, turmaBox.dataset["descricaoEstendida"])
      .replace(/DESCRICOES_HORARIOS/g, horariosContent)
      .replace(/DESCRICOES_PRE_REQUISITOS/g, prerequisitosContent)
      .replace(/DESCRICOES_CO_REQUISITOS/g, corequisitosContent)
      .replace(/TURMA_ID/g, turmaBox.dataset["turmaId"]);
    this.inscricoesListTarget.insertAdjacentHTML("beforeend", content);
    turmaBox.parentNode.removeChild(turmaBox);
    this.updateSubmit();
  }

  remover(event) {
    event.preventDefault();

    const inscricaoBox = event.target.closest(".nested-fields");
    const horariosBox = inscricaoBox.querySelector(".horarios");
    const prerequisitosBox = inscricaoBox.querySelector(".pre-requisitos");
    const corequisitosBox = inscricaoBox.querySelector(".co-requisitos");
    const horariosContent = horariosBox ? horariosBox.outerHTML : "";
    const prerequisitosContent = prerequisitosBox ? prerequisitosBox.outerHTML : "";
    const corequisitosContent = corequisitosBox ? corequisitosBox.outerHTML : "";

    const content = this.turmaTemplateTarget.innerHTML
      .replace(/DESCRICAO_BASICA/g, inscricaoBox.dataset["descricaoBasica"])
      .replace(/DESCRICAO_ESTENDIDA/g, inscricaoBox.dataset["descricaoEstendida"])
      .replace(/DESCRICOES_HORARIOS/g, horariosContent)
      .replace(/DESCRICOES_PRE_REQUISITOS/g, prerequisitosContent)
      .replace(/DESCRICOES_CO_REQUISITOS/g, corequisitosContent)
      .replace(/TURMA_ID/g, inscricaoBox.dataset["turmaId"]);
    this.turmasListTarget.insertAdjacentHTML("beforeend", content);
    inscricaoBox.style.display = "none";
    inscricaoBox.querySelector("input[name*='_destroy']").value = "1"
    this.updateSubmit();
  }

  updateSubmit() {
    this.submitTarget.disabled = [...this.inscricoesListTarget.querySelectorAll('.turma')]
      .filter(element => element.offsetWidth > 0 && element.offsetHeight > 0)
      .length == 0;
  }
}
