import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["one", "another"]

  change(event) {
    if (!event.target.checked) { return }
    if (event.target === this.oneTarget) {
      this.anotherTarget.checked = !this.oneTarget.checked
    } else {
      this.oneTarget.checked = !this.anotherTarget.checked
    }
  }
}
